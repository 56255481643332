import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgSearchRequestIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 25 25" width="24px" {...props}>
    <path
      d="M20.906 11.083v-2a2 2 0 0 0-2-2h-12a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5m4 2a3 3 0 1 0 6 0 3 3 0 0 0-6 0m5.2 2.2 1.8 1.8"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
const Memo = memo(SvgSearchRequestIcon);
export default Memo;
